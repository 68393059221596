import ArtistImg1 from "../img/artist1.jpg";
import ArtistImg2 from "../img/artist2.jpg";
import ArtistImg3 from "../img/artist3.jpg";
import ArtistImg4 from "../img/artist4.jpg";

export const ClientsData=[{
    Client1:ArtistImg1,
    Client2:ArtistImg2,
    Client3:ArtistImg3,
    Client4:ArtistImg4,
}]