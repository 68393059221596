import VoiceImg from "../img/serv_01-750x525.jpg";
import SoundImg from "../img/serv_02-750x525.jpg";

export const ServicesData = [
  {
    src1: VoiceImg,
    heading1: "Voice Recording",
    BtnText1: "Read More",
    src2: SoundImg,
    heading2: "Sound Mixing",
    BtnText2: "Read More",
  },
];
