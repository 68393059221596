import logo from "../img/logo.png";

export const NavData=[{
    src:logo,
    li1:"Home",
    li2:"About",
    // li3:"Products",
    // li4:"Studios",
    // li5:"Pages",
    // li6:"Contact",
}]