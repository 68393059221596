import img1 from "../img/partner-1.png";
import img2 from "../img/partner-2.png";
import img3 from "../img/partner-3.png";
import img4 from "../img/partner-4.png";
import img5 from "../img/partner-5.png";
import img6 from "../img/partner-6.png";

export const AnimationData = [
  {
    icon1: img1,
    icon2: img2,
    icon3: img3,
    icon4: img4,
    icon5: img5,
    icon6: img6,
  },
  {
    icon1: img1,
    icon2: img2,
    icon3: img3,
    icon4: img4,
    icon5: img5,
    icon6: img6,
  },
];