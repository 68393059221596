import img1 from "../img/about-monitors.png";
import img2 from "../img/about-guitar.png";
import img3 from "../img/about-waves.png";
import icon1 from "../img/Annotation 2020-06-20 171955.png";
import icon2 from "../img/Annotation 2020-06-20 172046.png";
import icon3 from "../img/Annotation 2020-06-20 172120.png";
import icon4 from "../img/Annotation 2020-06-20 172146.png";
import icon5 from "../img/Annotation 2020-06-20 171629.png";
import icon6 from "../img/Annotation 2020-06-20 172213.png";


export const WhyUsData=[{
    SpeakerImg:img1,
    GuitarImg:img2,
    WaveImg:img3,
    li1:icon1,
    li2:icon2,
    li3:icon3,
    li4:icon4,
    li5:icon5,
    li6:icon6,
}]